// Generated by Framer (e1877f1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Tu8mVauYT", "evWPIx1Zi", "INltbAtHL"];

const serializationHash = "framer-gsp2Z"

const variantClassNames = {evWPIx1Zi: "framer-v-68u2ht", INltbAtHL: "framer-v-ciyvwp", Tu8mVauYT: "framer-v-1difbf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Dark: "INltbAtHL", Light: "Tu8mVauYT", Small: "evWPIx1Zi"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Tu8mVauYT"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Tu8mVauYT", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1difbf", className, classNames)} data-framer-name={"Light"} layoutDependency={layoutDependency} layoutId={"Tu8mVauYT"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({evWPIx1Zi: {"data-framer-name": "Small"}, INltbAtHL: {"data-framer-name": "Dark"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1pb5016"} layoutDependency={layoutDependency} layoutId={"ynxhLNcsv"} style={{backgroundColor: "var(--token-c09d492a-e729-4cbb-b155-5952e8c0f0ee, rgb(250, 245, 234))"}} variants={{INltbAtHL: {backgroundColor: "var(--token-6486a9ac-e9e8-4ced-becb-afb4c654ba35, rgb(0, 0, 0))"}}}/><motion.div className={"framer-ojsu3e"} layoutDependency={layoutDependency} layoutId={"Tqj5dkbVs"} style={{backgroundColor: "var(--token-c09d492a-e729-4cbb-b155-5952e8c0f0ee, rgb(250, 245, 234))", rotate: 90}} variants={{INltbAtHL: {backgroundColor: "var(--token-6486a9ac-e9e8-4ced-becb-afb4c654ba35, rgb(0, 0, 0))"}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gsp2Z.framer-in7jy7, .framer-gsp2Z .framer-in7jy7 { display: block; }", ".framer-gsp2Z.framer-1difbf { height: 40px; overflow: hidden; position: relative; width: 40px; }", ".framer-gsp2Z .framer-1pb5016 { flex: none; height: 60px; left: 0px; overflow: hidden; position: absolute; top: 0px; width: 1px; }", ".framer-gsp2Z .framer-ojsu3e { flex: none; height: 60px; left: 29px; overflow: hidden; position: absolute; top: -29px; width: 1px; }", ".framer-gsp2Z.framer-v-68u2ht.framer-1difbf { height: 8px; width: 8px; }", ".framer-gsp2Z.framer-v-68u2ht .framer-1pb5016 { bottom: 0px; height: unset; }", ".framer-gsp2Z.framer-v-68u2ht .framer-ojsu3e { height: 9px; left: 3px; top: -4px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"evWPIx1Zi":{"layout":["fixed","fixed"]},"INltbAtHL":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerll8wYIuwU: React.ComponentType<Props> = withCSS(Component, css, "framer-gsp2Z") as typeof Component;
export default Framerll8wYIuwU;

Framerll8wYIuwU.displayName = "Corner";

Framerll8wYIuwU.defaultProps = {height: 40, width: 40};

addPropertyControls(Framerll8wYIuwU, {variant: {options: ["Tu8mVauYT", "evWPIx1Zi", "INltbAtHL"], optionTitles: ["Light", "Small", "Dark"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerll8wYIuwU, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})